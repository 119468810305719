import Vue from 'vue';
import Clipboard from 'clipboard';

function clipboardSuccess() {
  Vue.prototype.$message({
    message: '复制成功',
    type: 'success',
    duration: 1500
  });
}

function clipboardError() {
  Vue.prototype.$message({
    message: '复制失败,请手动复制',
    type: 'error'
  });
}

export function copy(_text, event) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var clipboard = new Clipboard(event.target, {
    text: function text() {
      return _text;
    }
  });
  clipboard.on('success', function () {
    options.success ? options.success() : clipboardSuccess();
    clipboard.destroy();
  });
  clipboard.on('error', function () {
    options.error ? options.error() : clipboardError();
    clipboard.destroy();
  });
  clipboard.onClick(event);
}